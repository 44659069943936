import {Color, ThemeGetter} from '@styles/types';

/**
 * Use `headlineColor` from the theme.
 * If `headlineColor` is not provided by the theme,
 * will fall back to using the theme's `fg` color.
 */
export const headlineColor: ThemeGetter<Color> = ({theme}): Color =>
  theme.headlineColor || theme.fg;

  /**
   * Use `headlineColor` from the theme.
   * If `headlineColor` is not provided by the theme,
   * will fall back to using the theme's `fg` color.
   */
  export const stairHeadlineColor: ThemeGetter<Color> = ({theme}): Color =>
    theme.stairHeadlineColor || theme.headlineColor;

/**
 * Use `paragraphColor` from the theme.
 * If `paragraphColor` is not provided by the theme,
 * will fall back to using the theme's `fg` color.
 */
export const paragraphColor: ThemeGetter<Color> = ({theme}): Color =>
  theme.paragraphColor || theme.fg;
