import React, {Fragment, useReducer, useCallback, useEffect} from 'react';
import {useSpring, animated, AnimatedProps} from 'react-spring';
import omit from 'lodash.omit';
import {useScrollDirection, useScrollIntersection} from '@hzdg/scroll-monitor';
import useSize, {Size} from '@hzdg/use-size';
import useRefCallback from '@hzdg/use-ref-callback';
import {NavigationLogo} from '@components/icons';
import {Link} from '@components/Link';
import {Nav} from '@components/sectioning';
import {usePageContext} from '@components/Page';
import useLocation from '@components/Subnavigation/useLocation';
import {
  useContainerSize,
  ResponsiveContainer,
  StickyPortal,
  Fixed,
  Sticky,
  FontScale,
} from '@components/layout';
import {
  StickyProps,
  StickyPortalProps,
  FixedProps,
} from '@components/layout/Sticky';

import {styled, Colors, Layers} from '@styles';
import MobileMenu from './MobileMenu';
import DesktopMenu from './DesktopMenu';
import {MenuState} from './types';

const SHOW_BACKGROUND_THRESHOLD = 75;

const bannerRef = React.createRef<HTMLDivElement>();

const StickyBannerPortal = (
  props: Omit<StickyPortalProps, 'ref'>,
): JSX.Element => <StickyPortal ref={bannerRef} {...props} />;

export const Banner = (props: Omit<FixedProps, 'portalRef'>): JSX.Element => (
  <Fixed {...props} portalRef={bannerRef} />
);

const menuRef = React.createRef<HTMLDivElement>();

export interface StickyMenuPortalProps extends Omit<StickyPortalProps, 'ref'> {
  menuSize: Size;
}
export type StickyMenuProps = Omit<StickyProps, 'portalRef'>;

const StickyMenuPortal = (props: StickyMenuPortalProps): JSX.Element => (
  <FontScale
    as={StickyPortal}
    ref={menuRef}
    style={{
      top: `${props.menuSize ? props.menuSize.height : 0}px`,
    }}
  />
);

export const StickyMenu = (props: StickyMenuProps): JSX.Element => (
  <Sticky {...props} portalRef={menuRef} />
);

const BannerContainer = styled.div.withConfig({
  componentId: 'menuBannerContainer'
})<{size: Size}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${Layers.Menu};
  width: 100%;
  /* This transform is applied to the containing div to establish
   * a new containing block, so that we can fix the position of
   * the StickyPortal to it rather than the viewport.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/position#fixed
   */
  transform: translateY(0px);
  height: ${({size}) => (size ? size.height : 0)}px;
`;

interface NavContainerProps
  extends AnimatedProps<React.HTMLProps<HTMLDivElement>> {
  bannerSize: Size;
  isMenuOpened: boolean;
}
const NavContainer = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLDivElement, NavContainerProps>((props, ref) => (
    <animated.div ref={ref} {...omit(props, 'bannerSize', 'isMenuOpened')} />
  )),
).withConfig({
  componentId: 'menuBannerNavContainer'
})`
  position: fixed;
  top: ${({bannerSize}) => (bannerSize ? bannerSize.height : 0)}px;
  left: 0;
  z-index: ${({isMenuOpened}) => (isMenuOpened ? Layers.Overlay : Layers.Menu)};
  pointer-events: ${({isMenuOpened}) => (isMenuOpened ? 'auto' : 'inherit')};
  width: 100vw;
  /* This transform is applied to the containing div to establish
   * a new containing block, so that we can fix the position of
   * the StickyPortal to it rather than the viewport.
   * See https://developer.mozilla.org/en-US/docs/Web/CSS/position#fixed
   */
  transform: translateY(0px);
`;

const NavContent = styled(Nav).attrs({role: 'navigation'}).withConfig({
  componentId: 'menuBannerNavContent'
})`
  flex: 1;
  padding: 1em 2em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 2em);
  position: relative;
  &[data-shouldshowshade='true'].wide:after {
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: ${Layers.Background};
    background: ${Colors.White};
  }
`;

const NavBackground = styled(animated.div).withConfig({
  componentId: 'menuBannerNavBackground'
})`
  position: absolute;
  z-index: ${Layers.Background};
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: ${Colors.White};
  &[data-ismenuopened='true'] {
    background: ${Colors.Midnight};
  }
`;

const MenuContentContainer = styled(FontScale).attrs({role: 'menubar'}).withConfig({
  componentId: 'menuBannerMenuContentContainer'
})`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  height: auto;
  .wide & {
    height: 100%;
    justify-content: space-between;
  }
`;

const LogoLink = styled(
  ({
    shouldInvertColors, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...props
  }: {
    shouldInvertColors?: boolean;
    href: string;
  }) => <Link {...props} />,
).withConfig({
  componentId: 'menuBannerLogoLink'
})`
  outline-color: ${({shouldInvertColors}) =>
    shouldInvertColors ? Colors.Blue : Colors.White};
  display: flex;
  align-items: center;
`;

interface MenuContentProps {
  toggleMenu: () => void;
  toggleSearch: () => void;
  resetMenuState: () => void;
  state: MenuState;
  shouldInvertColors: boolean;
}

function MenuContent({
  toggleMenu,
  toggleSearch,
  resetMenuState,
  state: {isMenuOpened, isSearchExpanded},
  shouldInvertColors,
  shouldApplyShade,
}: MenuContentProps): JSX.Element {
  const {wide} = useContainerSize();
  useEffect(resetMenuState, [wide]);
  return wide ? (
    <DesktopMenu
      onSearchClick={toggleSearch}
      isSearchExpanded={isSearchExpanded}
      shouldInvertColors={shouldInvertColors}
    />
  ) : (
    <MobileMenu
      onMenuClick={toggleMenu}
      onSearchClick={toggleSearch}
      isMenuOpened={isMenuOpened}
      isSearchExpanded={isSearchExpanded}
      shouldInvertColors={shouldInvertColors}
      shouldApplyShade={shouldApplyShade}
    />
  );
}

const initialState: MenuState = {
  isMenuOpened: false,
  isSearchExpanded: false,
};

const MENU_RESET = 'MENU_RESET';
const MENU_TOGGLE = 'MENU_TOGGLE';
const SEARCH_TOGGLE = 'SEARCH_TOGGLE';

type MenuActionType =
  | typeof MENU_RESET
  | typeof MENU_TOGGLE
  | typeof SEARCH_TOGGLE;

type MenuAction = {type: MenuActionType};

function menuStateReducer(state: MenuState, action: MenuAction): MenuState {
  switch (action.type) {
    case MENU_RESET: {
      let nextState = state;
      if (nextState.isMenuOpened)
        nextState = {...nextState, isMenuOpened: false};
      if (nextState.isSearchExpanded)
        nextState = {...nextState, isSearchExpanded: false};
      return nextState;
    }
    case MENU_TOGGLE: {
      return {...state, isMenuOpened: !state.isMenuOpened};
    }
    case SEARCH_TOGGLE: {
      return {...state, isSearchExpanded: !state.isSearchExpanded};
    }
    default: {
      return state;
    }
  }
}

export default function Menu(): JSX.Element {
  const [state, dispatch] = useReducer(menuStateReducer, initialState);
  const toggleMenu = useCallback(() => dispatch({type: MENU_TOGGLE}), []);
  const toggleSearch = useCallback(() => dispatch({type: SEARCH_TOGGLE}), []);
  const resetMenuState = useCallback(() => dispatch({type: MENU_RESET}), []);
  const [ref, setRef] = useRefCallback<HTMLDivElement>(null);
  const bannerSize = useSize(bannerRef);
  const size = useSize(ref);
  const {vertical: direction} = useScrollDirection(ref);
  const canShowBackground = useScrollIntersection(ref, {
    top: SHOW_BACKGROUND_THRESHOLD - (bannerSize ? bannerSize.height : 0),
  });

  const {
    shouldHideMenu,
    shouldInvertMenuColors,
    shouldShowShade,
  } = usePageContext({
    shouldInvertMenuColors: Boolean(state.isMenuOpened || canShowBackground),
    shouldHideMenu: direction === 'down' && (!bannerSize || !bannerSize.height),
  });

  const shouldApplyShade = !canShowBackground && shouldShowShade;

  const containerTransform = useSpring({
    to: {transform: `translateY(${shouldHideMenu ? -size.height : 0}px)`},
    config: {mass: 1, tension: 210, friction: 26, clamp: true},
  });

  const backgroundOpacity = useSpring({
    to: {opacity: shouldInvertMenuColors ? 1 : 0},
    config: {mass: 1, tension: 270, friction: 26, clamp: true},
  });

  const location = useLocation();
  useEffect(() => {
    dispatch({type: MENU_RESET});
  }, [location]);

  return (
    <Fragment>
      <BannerContainer size={bannerSize}>
        <StickyBannerPortal />
      </BannerContainer>
      <NavContainer
        ref={setRef}
        bannerSize={bannerSize}
        isMenuOpened={state.isMenuOpened}
        style={containerTransform}
      >
        <ResponsiveContainer
          as={NavContent}
          data-ismenuopened={state.isMenuOpened}
          data-shouldshowbackground={shouldInvertMenuColors || shouldApplyShade}
          data-shouldshowshade={shouldApplyShade}
        >
          <NavBackground
            style={backgroundOpacity}
            data-ismenuopened={state.isMenuOpened}
          />
          <LogoLink
            href="/"
            aria-label="2U homepage"
            shouldInvertColors={shouldInvertMenuColors || shouldApplyShade}
          >
            <NavigationLogo
              shouldInvertColors={shouldInvertMenuColors || shouldApplyShade}
            />
          </LogoLink>
          <MenuContentContainer>
            <MenuContent
              toggleMenu={toggleMenu}
              toggleSearch={toggleSearch}
              resetMenuState={resetMenuState}
              state={state}
              shouldInvertColors={shouldInvertMenuColors || shouldApplyShade}
              shouldApplyShade={shouldApplyShade}
            />
          </MenuContentContainer>
        </ResponsiveContainer>
        <StickyMenuPortal menuSize={size} />
      </NavContainer>
    </Fragment>
  );
}
