import React from 'react';
import {Link as GatsbyLink, GatsbyLinkProps, navigate} from 'gatsby';

import {styled, css, theme, Fonts} from '@styles';
import {animated, SpringValue} from 'react-spring';

export interface LinkProps<TState>
  extends Omit<GatsbyLinkProps<TState>, 'to' | 'ref'> {
  href: string;
  activeStyle?: React.CSSProperties;
  animatedStyle?: {[key: string]: SpringValue<unknown>};
}

interface TButtonTextStyles {
  textTransform?: string;
}

export const DefaultLinkTextStyles = css<TButtonTextStyles>`
  ${Fonts.ProximaNova};
  font-size: 1em;
  color: ${theme.ctaFg};
  text-decoration: none;
  text-transform: ${({textTransform}) => textTransform};
  text-align: center;
  cursor: pointer;
`;

const StyledInternalLink = styled(animated(GatsbyLink)).withConfig({
  componentId: 'linkInternalLink'
})`
  ${DefaultLinkTextStyles}
`;

const StyledExternalLink = styled(animated.a).withConfig({
  componentId: 'linkExternalLink'
})`
  ${DefaultLinkTextStyles}
`;

export const isHrefInternal = (href: string): boolean =>
  /^\/(?!\/|static\/)/.test(href ?? '');

export default function Link<TState>({
  children,
  href,
  className,
  activeStyle,
  style: withStyle,
  animatedStyle,
  partiallyActive,
  onClick,
  ...rest
}: LinkProps<TState>): JSX.Element {
  const style = {...animatedStyle, ...withStyle};
  /** if the link navigates to a page within the app,
   * the content of that page will be prefetched by gatsby's Link
   * component. If it is an external link, a default a tag will
   * be rendered.
   */
  if (isHrefInternal(href)) {
    return (
      <StyledInternalLink
        className={className}
        to={href}
        activeStyle={activeStyle}
        partiallyActive={partiallyActive}
        style={style}
        onClick={onClick}
        {...rest}
      >
        {children}
      </StyledInternalLink>
    );
  }
  return (
    <StyledExternalLink
      href={href}
      className={className}
      onClick={onClick}
      style={style}
      target="_blank"
      aria-label={`external link. ${children}`}
      {...rest}
    >
      {children}
    </StyledExternalLink>
  );
}
