/** Constants for when z-order needs to managed manually. */

/** The layer for stuff that goes behind _everything_. */
export const Background = -1;
/** The layer for content. */
export const Content = 0;
/** The layer for menu stuff. */
export const Menu = 1;
/** The layer for stuff that goes on top of _everything_. */
export const Overlay = 2;
