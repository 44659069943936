import {css} from './styled';

import {FlattenSimpleInterpolation} from 'styled-components';

type Font = FlattenSimpleInterpolation;

type Variants = {
  readonly variants: {
    readonly [key: string]: Font;
  };
} & {
  readonly [key: string]: Font;
};

function Font<T, V>(value: T, variants: V): T & V;
function Font<T>(value: T): T;
function Font(
  value: FlattenSimpleInterpolation,
  variants?: Variants,
): Font | Font & Variants {
  if (variants) {
    return Object.create(
      value,
      Object.entries(variants).reduce(
        (properties, [name, font]) => Object.assign(properties, {[name]: font}),
        {
          variants: {
            value: variants,
          },
        },
      ),
    );
  }
  return value;
}

export const ProximaNova = Font(
  css`
    font-family: proxima-nova, sans-serif;
    font-weight: 400;
  `,
  {
    Italic: Font(css`
      font-family: proxima-nova, sans-serif;
      font-weight: 400;
      font-style: italic;
    `),
    Bold: Font(css`
      font-family: proxima-nova, sans-serif;
      font-weight: 600;
    `),
    ExtraBold: Font(css`
      font-family: proxima-nova, sans-serif;
      font-weight: 800;
    `),
  },
);

export default {
  ProximaNova,
};
