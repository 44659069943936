import React, {useMemo, useState, useEffect} from 'react';
import {Sizes, Scales} from '@styles';
import {useWindowSize, WindowSize} from '@hzdg/windowsize-monitor';

interface FontScaleComponentProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

export interface FontScaleProps extends FontScaleComponentProps {
  /**
   * The React element type to render. Defaults to `'div'`.
   * If the provided value is not a react-dom component,
   * it should forward the provided ref and styles to an
   * underlying component.
   * See https://reactjs.org/docs/forwarding-refs.html
   */
  as?: React.ElementType;
  /**
   * The minimum font scale.
   * Defaults to `Scales.Small`.
   */
  min?: number;
  /**
   * The maximum font scale.
   * Defaults to `Scales.Large`.
   */
  max?: number;
  /**
   * The container width below which the font will stop scaling down.
   * At this size, the `min` font scale will be applied.
   * Defaults to `Sizes.Narrow`.
   */
  minWidth?: number;
  /**
   * The container width above which the font will stop scaling up.
   * At this size, the `max` font scale will be applied.
   * Defaults to `Sizes.LudicrousWide`.
   */
  maxWidth?: number;
}

const getFontScaleStyle = (
  size: WindowSize,
  min: number,
  max: number,
  minWidth: number,
  maxWidth: number,
  mergeWithStyle?: React.CSSProperties,
): React.CSSProperties => {
  let scale = min;
  if (size.width >= maxWidth) {
    scale = max;
  } else if (size.width > minWidth) {
    const scaleOffset = Math.max(0, max - min);
    const sizeFactor =
      Math.max(1, size.width - minWidth) / Math.max(1, maxWidth - minWidth);
    scale += Math.round(scaleOffset * sizeFactor * 100) / 100;
  }
  return {...mergeWithStyle, fontSize: `${scale * 100}%`};
};

/**
 * A Component that scales it's childrens' font sizes
 * between a `min` and `max` scale.
 */
const FontScale = React.forwardRef<HTMLElement, FontScaleProps>(
  function FontScale(
    {
      as: Component = 'div',
      min = Scales.Small,
      max = Scales.Large,
      minWidth = Sizes.Narrow,
      maxWidth = Sizes.LudicrousWide,
      style: withStyle,
      children,
      ...props
    }: FontScaleProps,
    forwardedRef?: React.Ref<HTMLElement> | null,
  ): JSX.Element {
    // Force an update on mount to patch inline styles after hydration.
    // See https://reactjs.org/docs/react-dom.html#hydrate
    const [mounted, setMounted] = useState(false);
    useEffect(() => setMounted(true), []);
    const windowSize = useWindowSize();
    const style = useMemo(
      () =>
        getFontScaleStyle(
          mounted ? windowSize : {width: 0, height: 0},
          min,
          max,
          minWidth,
          maxWidth,
          withStyle,
        ),
      [mounted, windowSize, min, max, minWidth, maxWidth, withStyle],
    );
    return (
      <Component {...props} style={style} ref={forwardedRef}>
        {children}
      </Component>
    );
  },
);

FontScale.displayName = 'FontScale';

export default FontScale;
