import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useRef,
} from 'react';
import shallowEqual from 'shallowequal';

const UPDATE_BASE_CONFIG = 'UPDATE_BASE_CONFIG';
const ADD_CONFIG = 'ADD_CONFIG';
const DELETE_CONFIG = 'DELETE_CONFIG';
const REPLACE_STATE = 'REPLACE_STATE';

/**
 * `ConfigState` keeps atomic descriptors
 * of a composite configuration. It is used both to read
 * the current state and to calculate the next state.
 */
interface ConfigState<Config> {
  /**
   * The initial configuration passed to `createConfigContext`.
   * This value will never change, but is here convenience when
   * calculating a new state.
   */
  initialConfig: Config;
  /**
   * The base config value optionally passed to `ConfigProvider`.
   */
  baseConfig?: Partial<Config> | null;
  /**
   * The last calculated composite config.
   */
  currentConfig: Config;
  /**
   * A set of configs to compose with `initialConfig` and `baseConfig`.
   * They are composed in order, meaning the last value seen in the set
   * 'wins'.
   *
   * Note that this is _not_ a set of config objects.
   * Rather, it is a set of _React refs_ to config objects.
   * This is because React ref objects are referentially stable between
   * renders, while the config objects passed to `useConfig` may not be.
   * This allows extra config provided by a `useConfig` Component to
   * be easily added and removed with the lifecycle of the Component.
   */
  configStack: Set<React.RefObject<Partial<Config> | undefined>>;
}

/**
 * `ReplaceStateAction` will replace the entire config state
 *  with it's `payload`.
 */
interface ReplaceStateAction<Config> {
  type: typeof REPLACE_STATE;
  payload: ConfigState<Config>;
}

/**
 * `UpdateBaseConfigAction` will set the `baseConfig` state
 * to it's `payload`.
 */
interface UpdateBaseConfigAction<Config> {
  type: typeof UPDATE_BASE_CONFIG;
  payload?: Partial<Config> | null;
}

/**
 * `AddConfigAction` will add its `payload` to the state's `configStack`.
 */
interface AddConfigAction<Config> {
  type: typeof ADD_CONFIG;
  payload: React.RefObject<Partial<Config> | undefined>;
}

/**
 * `DeleteConfigAction` will delete its `payload` from the state's `configStack`.
 */
interface DeleteConfigAction<Config> {
  type: typeof DELETE_CONFIG;
  payload: React.RefObject<Partial<Config> | undefined>;
}

type ConfigAction<Config> =
  | AddConfigAction<Config>
  | DeleteConfigAction<Config>
  | UpdateBaseConfigAction<Config>
  | ReplaceStateAction<Config>;

/**
 * `DispatchWithConfig` is a callback that will either merge a given config
 * with the current config or remove the given config from the current config,
 * depending on whether it is passed an `ADD_CONFIG` or `DELETE_CONFIG` action,
 * and then dispatch the updated config to other config users.
 *
 * If the action is `ADD_CONFIG`, it will return the updated config
 * immediately. Otherwise, it will return `undefined`.
 */
export interface DispatchWithConfig<Config> {
  (action: AddConfigAction<Config>): Config;
  (action: DeleteConfigAction<Config>): undefined;
}

export type ConfigProviderProps<Config> = React.PropsWithChildren<{
  value?: Config;
}>;

function updateConfigState<Config>(
  state: ConfigState<Config>,
): ConfigState<Config> {
  const {initialConfig, baseConfig, currentConfig, configStack} = state;
  const nextConfig = {...initialConfig};
  if (baseConfig) {
    Object.assign(nextConfig, baseConfig);
  }
  for (const config of configStack) {
    if (config.current) {
      Object.assign(nextConfig, config.current);
    }
  }
  if (!shallowEqual(nextConfig, currentConfig)) {
    return {...state, currentConfig: nextConfig};
  } else {
    return state;
  }
}

function reduceConfigState<Config>(
  state: ConfigState<Config>,
  action: ConfigAction<Config>,
): ConfigState<Config> {
  let nextState = state;
  switch (action.type) {
    case UPDATE_BASE_CONFIG: {
      if (action.payload !== state.baseConfig) {
        nextState = updateConfigState({...state, baseConfig: action.payload});
      }
      break;
    }
    case ADD_CONFIG: {
      if (!state.configStack.has(action.payload)) {
        state.configStack.add(action.payload);
      }
      nextState = updateConfigState(state);
      break;
    }
    case DELETE_CONFIG: {
      state.configStack.delete(action.payload);
      nextState = updateConfigState(state);
      break;
    }
    case REPLACE_STATE: {
      nextState = action.payload;
      if (
        shallowEqual(nextState.currentConfig, state.currentConfig) &&
        shallowEqual(nextState, state)
      ) {
        nextState = state;
      }
      break;
    }
  }
  return nextState;
}

/**
 * `createConfigContext` will create a `[useConfig, ConfigProvider]`
 * pair.
 *
 * `ConfigProvider` will provide the current config value
 * (initialized with `initialConfig`) to a Component tree.
 *
 * `useConfig` will allow Components in the tree to access
 * and modify the current config value.
 */
export default function createConfigContext<Config>(
  /**
   * `initialConfig` will 'seed' the provided config.
   *
   * Any value passed to `ConfigProvider` will be merged with the
   * `initialConfig`. Any additional config added via `useConfig`
   * will then be merged. The resulting value will be provided
   * to all config users.
   */
  initialConfig: Config,
): [
  (withConfig?: Partial<Config>) => Config,
  React.FunctionComponent<ConfigProviderProps<Config>>,
] {
  const ConfigContext = createContext<DispatchWithConfig<Config>>(() => {
    throw new Error('ConfigProvider requires a dispatch callback!');
  });

  /**
   * `useConfig` will grab the current config.
   *
   * If passed additional config, it will merge with the current config.
   * The merged config will be returned immediately,
   * and then shared with any other config users.
   */
  function useConfig(
    /**
     * Optional config to merge with the current config.
     * The merged config will be returned immediately,
     * and then shared with any other config users.
     */
    withConfig?: Partial<Config>,
  ): Config {
    const dispatch = useContext(ConfigContext);
    const cleanup = useRef(dispatch);
    const config = useRef(withConfig);
    config.current = withConfig;
    cleanup.current = dispatch;
    useEffect(
      () => () => {
        cleanup.current({type: DELETE_CONFIG, payload: config});
      },
      [cleanup],
    );
    return dispatch({type: ADD_CONFIG, payload: config});
  }

  /**
   * `ConfigProvider` will provide a config to a component tree.
   * Components in the tree can `useConfig` to access and modify
   * the config.
   */
  function ConfigProvider({
    /**
     * Optional base config value to use.
     *
     * If provided, it will be merged with the `initialConfig`
     * passed to `createConfig`. Any additional config added via `useConfig`
     * will then be merged with this config and the `initialConfig`.
     * The resulting merged value will be provided to all config users.
     */
    value,
    children,
  }: ConfigProviderProps<Config>): JSX.Element {
    const [configState, dispatch] = useReducer<
      React.Reducer<ConfigState<Config>, ConfigAction<Config>>,
      ConfigState<Config> | null
    >(reduceConfigState, null, () => ({
      initialConfig,
      baseConfig: value,
      currentConfig: value ? {...initialConfig, ...value} : initialConfig,
      configStack: new Set(),
    }));

    useEffect(
      /**
       * `updateBaseConfig` will dispatch an update
       * whenever the `value` passed to `ConfigProvider` changes.
       */
      function updateBaseConfig() {
        dispatch({type: UPDATE_BASE_CONFIG, payload: value});
      },
      [value],
    );

    /**
     * `dispatchWithConfig` will be consumed by the `useConfig` hook
     * to read and write to the config transparently. Thanks to this
     * function, Components that `useConfig` will not have to worry about
     * dispatching updates to the config state—they will simply `useConfig()`,
     * optionally passing in some additional config to merge.
     */
    function dispatchWithConfig(action: AddConfigAction<Config>): Config;
    function dispatchWithConfig(action: DeleteConfigAction<Config>): undefined;
    function dispatchWithConfig(
      action: AddConfigAction<Config> | DeleteConfigAction<Config>,
    ): Config | undefined {
      const nextState = reduceConfigState(configState, action);
      if (nextState !== configState) {
        dispatch({type: REPLACE_STATE, payload: nextState});
      }
      if (action.type === ADD_CONFIG) {
        return nextState.currentConfig;
      }
    }

    // Memoize `dispatchWithConfig` so we only update
    // config context consumers when the config has changed.
    const providerValue: DispatchWithConfig<Config> = useCallback(
      dispatchWithConfig,
      [configState],
    );

    return (
      <ConfigContext.Provider value={providerValue}>
        {children}
      </ConfigContext.Provider>
    );
  }

  return [useConfig, ConfigProvider];
}
