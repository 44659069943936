import React, {Fragment} from 'react';
import MenuItem from './MenuItem';
import {MenuItemProps, MenuItemsContainerProps} from './types';

export default function MenuItems({
  items,
  utility,
  shouldInvertColors,
}: MenuItemsContainerProps): JSX.Element {
  return (
    <Fragment>
      {items.map((item: MenuItemProps) => (
        <MenuItem
          key={item.text}
          utility={utility}
          shouldInvertColors={shouldInvertColors}
          {...item}
        />
      ))}
    </Fragment>
  );
}
