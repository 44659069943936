import React from 'react';
import loadable from '@loadable/component';
import {LatestCategoriesData} from './useLatestCategories';

export type LatestCategoriesRenderProps = LatestCategoriesData;

export interface LatestCategoriesDataLoaderProps {
  children: (props: LatestCategoriesRenderProps) => JSX.Element;
}

const LatestCategoriesLoader = loadable.lib(() =>
  import(
    /* webpackChunkName: "latest-categories" */
    '@util/useLatestCategories'
  ),
);

interface LatestCategoriesDataRendererProps {
  children: LatestCategoriesDataLoaderProps['children'];
  useLatestCategories: () => LatestCategoriesData;
}

const LatestCategoriesDataRenderer = ({
  children,
  useLatestCategories,
}: LatestCategoriesDataRendererProps): JSX.Element => {
  const latestCategories = useLatestCategories();
  return children(latestCategories);
};

export default function LatestCategoriesDataLoader({
  children,
}: LatestCategoriesDataLoaderProps): JSX.Element {
  return (
    <LatestCategoriesLoader>
      {({default: useLatestCategories}) => (
        <LatestCategoriesDataRenderer useLatestCategories={useLatestCategories}>
          {children}
        </LatestCategoriesDataRenderer>
      )}
    </LatestCategoriesLoader>
  );
}
