import React, {Fragment} from 'react';

// import {MagnifyingGlass} from '@components/icons';
import {mainLinks, utilityLinks} from './navigationData';
import MenuItemsList from './MenuItemsList';
import {DesktopMenuProps} from './types';

import {styled, css} from '@styles';

export const SharedMenuLinksContainer = css`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
`;

export const MenuButton = styled.button.withConfig({
  componentId: 'desktopMenuMenuButton'
})`
  background: transparent;
  padding: 0;
  border: none;
  cursor: pointer;
`;

export const UtilityLinksContainer = styled.ul.attrs(() => ({
  role: 'menu',
  ['aria-label']: 'Utility navigation links',
})).withConfig({
  componentId: 'desktopMenuUtilityLinksContainer'
})`
  ${SharedMenuLinksContainer};
  order: 1;
  padding-bottom: 10px;
`;

export const MainLinksContainer = styled.ul.attrs(() => ({
  role: 'menu',
  ['aria-label']: 'Main navigation links',
})).withConfig({
  componentId: 'desktopMenuMainLinksContainer'
})`
  ${SharedMenuLinksContainer};
  order: 2;
`;

export default function DesktopMenuContent({
  // onSearchClick,
  // isSearchExpanded,
  shouldInvertColors,
}: DesktopMenuProps): JSX.Element {
  return (
    <Fragment>
      <MainLinksContainer>
        <MenuItemsList
          items={mainLinks}
          shouldInvertColors={shouldInvertColors}
        />
        {/** hiding the search menu button until phase 2 */}
        {/* {isSearchExpanded && <input type="text" />}
        <MenuButton onClick={onSearchClick}>
          <MagnifyingGlass margin="0 10px 0 0" />
        </MenuButton> */}
      </MainLinksContainer>
      <UtilityLinksContainer>
        <MenuItemsList
          utility={true}
          items={utilityLinks}
          shouldInvertColors={shouldInvertColors}
        />
      </UtilityLinksContainer>
    </Fragment>
  );
}
