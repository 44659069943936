import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledYoutubeIcon = styled.svg.withConfig({
  componentId: 'youtubeIcon'
})<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function YoutubeIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledYoutubeIcon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <path
        fillRule="nonzero"
        d="M15 30C6.71572875 30 0 23.2842712 0 15 0 6.71572875 6.71572875 0 15 0c8.2842712 0 15 6.71572875 15 15 0 8.2842712-6.7157288 15-15 15zm-1.6661366-12.5l-.0005301-5 4.5833334 2.5086197L13.3338634 17.5zM23.575 11.4463833s-.1711719-1.224619-.695625-1.76389563c-.6655469-.70811303-1.4114844-.71144533-1.7532812-.75254365C18.6771875 8.75 15.0038281 8.75 15.0038281 8.75h-.0076562s-3.6733594 0-6.12226565.17994402c-.34234375.04109832-1.08773438.04443062-1.75382813.75254365C6.595625 10.2217643 6.425 11.4463833 6.425 11.4463833s-.175 1.4384414-.175 2.8763274v1.3484694c0 1.4384414.175 2.8763273.175 2.8763273s.170625 1.224619.69507812 1.7638957c.66609375.7081131 1.54054688.6858977 1.92992188.7597636C10.45 21.2077909 15 21.25 15 21.25s3.6771875-.0055538 6.1260938-.1854978c.3417968-.0416538 1.0877343-.044986 1.7532812-.7530991.5244531-.5392767.695625-1.7638957.695625-1.7638957s.175-1.4378859.175-2.8763273v-1.3484694c0-1.437886-.175-2.8763274-.175-2.8763274z"
      />
    </StyledYoutubeIcon>
  );
}
