import React from 'react';

import {theme, styled, Colors} from '@styles';
import {SvgIconProps} from '.';

const StyledX = styled.svg.withConfig({
  componentId: 'styledX'
})<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & g {
    fill: ${({fill}) => (fill ? fill : Colors.White)};
  }
`;

export default function X({fill, margin}: SvgIconProps): JSX.Element {
  return (
    <StyledX
      width="22"
      height="21"
      viewBox="0 0 22 21"
      margin={margin}
      fill={fill}
    >
      <g fillRule="nonzero" transform="translate(0 -1)">
        <rect
          width="28"
          height="3"
          x="-3"
          y="10"
          rx="1.5"
          transform="rotate(-45 11 11.5)"
        />
        <path d="M3.2218254 1.60050506L20.89949495 19.2781746c.58578642.58578642.58578642 1.53553393 0 2.12132035s-1.53553393.58578642-2.12132035 0L1.10050506 3.7218254c-.58578643-.58578644-.58578642-1.53553393 0-2.12132035s1.53553391-.58578643 2.12132035 0z" />
      </g>
    </StyledX>
  );
}
