export const utilityLinks = [
  // {
  //   href: 'https://www.edx.org/',
  //   text: 'edX',
  // },
  // {
  //   href: 'https://investor.2u.com/investor-relations/',
  //   text: 'Investors',
  // },
  // {
  //   href: '/press/',
  //   text: 'Press',
  // },
  // {
  //   href: '/contact/',
  //   text: 'Contact Us',
  // },
];

export const footerUtilityLinks = [
  {
    href: 'https://www.edx.org/',
    text: 'edX',
  },
  // {
  //   href: 'https://investor.2u.com/investor-relations/',
  //   text: 'Investors',
  // },
  {
    href: '/press/',
    text: 'Press',
  },
  // {
  //   href: '/contact/',
  //   text: 'Contact Us',
  // },
];

export const mainLinks = [
  {
    href: '/about/',
    text: 'About Us',
  },
  {
    href: 'https://www.edx.org/',
    text: 'edX',
  },
  // {
  //   href: '/approach/',
  //   text: 'Our Approach',
  // },
  {
    href: '/partners/',
    text: 'Our Partners',
  },
  // {
  //   href: 'https://investor.2u.com/',
  //   text: 'Investors',
  // },
  // {
  //   href: '/press/',
  //   text: 'Press',
  // },
  {
    href: '/careers/',
    text: 'Careers',
  },
  {
    href: '/newsroom/',
    text: 'Newsroom',
  },
  {
    href: '/privacy-policy/',
    text: 'Privacy',
  },
];
