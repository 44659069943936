import * as Colors from '@styles/colors';
import * as Sizes from '@styles/sizes';
import * as Spaces from '@styles/spaces';
import {Color, Size, Space} from '@styles/types';

/**
 * The base theme.
 * These values are used by default via a `ThemeProvider` at the app root.
 * See `gatsby-browser.js` and `gatsby-ssr.js` for more.
 */

/** The foreground color for a theme. Generally used as text color. */
export const fg: Color = Colors.Charcoal;
/** The background color for a theme. */
export const bg: Color = Colors.White;
/** The max width for a theme. */
export const maxWidth: Size = Sizes.LudicrousWide;
/** The min width for a theme. */
export const minWidth: Size = Sizes.Narrow;
/** The maximum amount of padding for a theme. */
export const maxPadding: Space = Spaces.LudicrousWide;
/** The minimum amount of padding for a theme. */
export const minPadding: Space = Spaces.Narrow;
