import {useEffect, useState} from 'react';
import {globalHistory, HistoryLocation, NavigateFn} from '@reach/router';

export interface UseLocationState {
  location: HistoryLocation;
  navigate: NavigateFn;
}

export default function useLocation(): UseLocationState {
  const initialState = {
    location: globalHistory.location,
    navigate: globalHistory.navigate,
  };

  const [state, setState] = useState(initialState);
  useEffect(() => {
    const removeListener = globalHistory.listen(params => {
      const {location} = params;
      const newState = Object.assign({}, initialState, {location});
      setState(newState);
    });
    return () => {
      removeListener();
    };
  }, [initialState]);
  return state;
}

// decodes uri cruft from the key and value of a url param pair.
const decodePair = (pair: string[]): string[] => [
  decodeURIComponent(pair[0] || ''),
  decodeURIComponent(pair[1] || ''),
];

export const parseQueryParams = (str: string): object | null => {
  const regex = /(\?|\&)([^=]+)\=([^&]+)/g; // NB: this will skip empty valued params
  const arr = str.match(regex) || [];
  const pairs = arr.map(v => v.slice(1).split('=')).map(decodePair);
  return arr ? Object.fromEntries(pairs) : null;
};
