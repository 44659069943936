import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import cx from 'classnames';
import useSize, {Size} from '@hzdg/use-size';
import useRefCallback from '@hzdg/use-ref-callback';
import {Sizes} from '@styles';
import useValueObject, {equalValueObject} from '@hzdg/use-value-object';

export interface BreakpointConfig {
  [key: string]: number;
}

export type ContainerSize<T extends BreakpointConfig> = {
  [k in keyof T]: boolean;
};

const defaultBreakpoints: BreakpointConfig = {
  narrow: Sizes.Narrow,
  wide: Sizes.Wide,
  extraWide: Sizes.ExtraWide,
  ludicrousWide: Sizes.LudicrousWide,
};

const defaultContainerSize = Object.keys(defaultBreakpoints).reduce(
  (final, keyName) => {
    return {
      ...final,
      [keyName]: false,
    };
  },
  {},
);

const ContainerContext = React.createContext({});

const getClassNames = <T extends ContainerSize<BreakpointConfig>>(
  config: T,
): (keyof T)[] => {
  const classNames = [];
  for (const name in config) {
    const value = config[name];
    if (value) {
      classNames.push(name);
    }
  }
  return classNames;
};

export const useContainerSize = (): ContainerSize<BreakpointConfig> => {
  return useContext(ContainerContext) as ContainerSize<BreakpointConfig>;
};

export type ContainerProps = React.PropsWithChildren<{
  /**
   * The React element type to render. Defaults to `'div'`.
   * If the provided value is not a react-dom component,
   * it should forward the provided ref to an underlying
   * component.
   * See https://reactjs.org/docs/forwarding-refs.html
   */
  as?: React.ElementType;

  /**
   * A map of breakpoint names to sizes.
   */
  breakpoints?: BreakpointConfig;

  /**
   * Additional className value for the container.
   * This value will be combined with classnames corresponding
   * to names defined by `breakpoints`.
   */
  className?: string;

  /**
   *  initial container size config unitl the app is loaded.
   *  Useful for SSR rendering
   */
  initialContainerSize?: ContainerSize<BreakpointConfig>;
}>;

/**
 * A generic container that translates responsive breakpoints into class names.
 *
 * Also acts as provider for descendants that might want to use breakpoints
 * programmatically via the `useConatinerSize` hook.
 *
 * **Note** this approach _is distinct_ from a traditional media query approach.
 * While media queries are very useful when you need to know information about
 * the browser environment, like how much space _a page_ has, they aren't
 * useful when you need to know localized information, like how much space
 * _a component_ has.
 *
 */
function ResponsiveContainer(
  {
    as: Component = 'div',
    breakpoints = defaultBreakpoints,
    className: withClassName,
    children,
    initialContainerSize = defaultContainerSize,
    ...props
  }: ContainerProps,
  forwardedRef?: React.Ref<HTMLElement | null> | null,
): JSX.Element {
  const [ref, setRef] = useRefCallback<HTMLElement>(null, forwardedRef);
  const size = useRef<Size | null>(null);
  const config = useValueObject(breakpoints);

  const getContainerSize = useCallback(
    (size: Size | null) => {
      const sizes: ContainerSize<typeof config> = {};
      if (size === null) return initialContainerSize;
      for (const name in config) {
        sizes[name] = size
          ? config[name] <= size.borderBoxSize.inlineSize
          : false;
      }
      return sizes;
    },
    [config],
  );

  const [containerSize, setContainerSize] = useState(() =>
    getContainerSize(size.current),
  );

  const className = useMemo(
    () => cx(withClassName, getClassNames(containerSize)),
    [withClassName, containerSize],
  );

  const updateContainerSize = useCallback(() => {
    const next = getContainerSize(size.current);
    setContainerSize(current =>
      equalValueObject(current, next) ? current : next,
    );
  }, [getContainerSize]);

  useSize(
    ref,
    useCallback(
      nextSize => {
        size.current = nextSize;
        updateContainerSize();
      },
      [updateContainerSize],
    ),
  );

  useEffect(updateContainerSize, [updateContainerSize]);

  return (
    <Component {...props} className={className} ref={setRef}>
      <ContainerContext.Provider value={containerSize}>
        {children}
      </ContainerContext.Provider>
    </Component>
  );
}

export default React.forwardRef<HTMLElement, ContainerProps>(
  ResponsiveContainer,
);
