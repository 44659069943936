import React, {CSSProperties} from 'react';
import {graphql} from 'gatsby';
import {useSpring, animated, UseSpringProps, config} from 'react-spring';
import useHover from '@hzdg/use-hover';

import {Link, DefaultLinkTextStyles} from '@components/Link';
import {styled, css, theme, useTheme, Fonts} from '@styles';

type ButtonProps = React.HTMLProps<HTMLButtonElement>;

type AnchorProps = React.HTMLProps<HTMLAnchorElement>;

export type CTAProps = (AnchorProps | ButtonProps) & {
  isActive?: boolean;
  ariaLabel?: string;
  style?: CSSProperties;
  ['data-testid']?: string;
};

const ButtonContainerStyles = css`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  background: ${theme.ctaBg};
  width: fit-content;
  border-radius: 50px;
  border: 2px solid ${theme.ctaBorder};
  will-change: background, border, color;
`;

const ButtonTextStyles = css`
  ${Fonts.ProximaNova};
  ${DefaultLinkTextStyles};
  font-weight: bold;
  text-align: center;
  padding: 0 calc(34px - 1em);
`;

const CtaContainer = styled.div.withConfig({
  componentId: 'ctaContainer'
})`
  ${ButtonContainerStyles};
  height: calc(40px - 4px);
`;

const StyledButton = styled.button.withConfig({
  componentId: 'ctaButton'
})<{isActive: boolean}>`
  ${ButtonContainerStyles};
  ${ButtonTextStyles};
  height: 40px;
  cursor: pointer;
  background: ${({isActive}) => isActive && theme.ctaActiveBg};
  color: ${({isActive}) => isActive && theme.ctaActiveFg};
`;

const CtaStyledLink = styled(Link).withConfig({
  componentId: 'catLink'
})`
  ${ButtonTextStyles};
  color: inherit;
  display: inline-flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const AnimatedCtaContainer = animated(CtaContainer);
const AnimatedStyledButton = animated(StyledButton);

const CTASpringConfig = {...config.stiff, clamp: true};

export default function CTA({
  onClick,
  href,
  isActive,
  value,
  ariaLabel,
  className,
  children,
  ...rest
}: CTAProps): JSX.Element {
  const theme = useTheme();

  const [isHovering, hoverProps] = useHover();
  const {color, background, border} = useSpring<UseSpringProps<{}>>({
    config: CTASpringConfig,
    to: {
      color: isActive
        ? theme.ctaActiveFg
        : isHovering
        ? theme.ctaHoverFg
        : theme.ctaFg,
      background: isActive
        ? theme.ctaActiveBg
        : isHovering
        ? theme.ctaHoverBg
        : theme.ctaBg,
      border: isHovering
        ? `2px solid ${theme.ctaBorderHover}`
        : `2px solid ${theme.ctaBorder}`,
    },
  });
  /**  if the onClick prop is passed _without an href prop_,
   * the component will return a button element with onClick event.
   * Otherwise, the returned element will be Container(div) > Link(a)
   */
  if (onClick && href == null) {
    return (
      <AnimatedStyledButton
        className={className}
        aria-label={ariaLabel}
        onClick={onClick}
        style={{color, background, border}}
        isActive={isActive}
        value={value}
        data-testid={rest['data-testid'] ? rest['data-testid'] : null}
        onBlur={hoverProps.onMouseLeave}
        onFocus={hoverProps.onMouseEnter}
        {...hoverProps}
      >
        {children}
      </AnimatedStyledButton>
    );
  }
  return (
    <AnimatedCtaContainer
      className={className}
      style={{color, background, border}}
      onBlur={hoverProps.onMouseLeave}
      onFocus={hoverProps.onMouseEnter}
      {...hoverProps}
    >
      <CtaStyledLink
        aria-label={ariaLabel}
        href={href as string}
        onClick={
          onClick as (event: React.MouseEvent<HTMLAnchorElement>) => void
        }
        data-testid={rest['data-testid'] ? rest['data-testid'] : null}
      >
        {children}
      </CtaStyledLink>
    </AnimatedCtaContainer>
  );
}

export const pagelinkBlockFragment = graphql`
  fragment pagelinkBlockFragment on CMS_PageLinkBlock {
    blockType
    id
    title
    page {
      url
      urlPath
    }
  }
`;

export const externalLinkBlockFragment = graphql`
  fragment externalLinkBlockFragment on CMS_ExternalLinkBlock {
    id
    value
    blockType
  }
`;

export const filelinkBlockFragment = graphql`
  fragment filelinkBlockFragment on CMS_FileLinkBlock {
    id
    file {
      url
    }
    caption
    blockType
  }
`;

export const fileBlockFragment = graphql`
  fragment fileBlockFragment on CMS_FileBlock {
    id
    file {
      url
    }
    caption
    blockType
  }
`;

export const externallinkBlockFragment = graphql`
  fragment externallinkBlockFragment on CMS_ExternalLinkBlock {
    id
    link
    blockType
    caption
  }
`;

export const ctablockFragment = graphql`
  fragment ctablockFragment on CMS_PageLinkBlock {
    blockType
    title
    page {
      url
      urlPath
    }
  }
`;
