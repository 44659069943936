import React, {useEffect, useCallback} from 'react';
import CTA from '@components/CTA';
import {X} from '@components/icons';
import {Paragraph} from '@components/typography';
import {MenuButton} from '@components/Menu/DesktopMenu';
import {Banner} from '@components/Menu';
import {Link} from '@components/Link';
import {
  ResponsiveContainer,
  useContainerSize,
  FontScale,
} from '@components/layout';
import {styled, ThemeProvider, Colors} from '@styles';

const PromoBarContainer = styled(FontScale).withConfig({
  componentId: 'promoBarContainer'
}).attrs(() => ({
  role: 'dialog',
}))`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 2em;
  width: calc(100% - 4em);
  background: ${Colors.White};
`;

const ButtonsContainer = styled.div.withConfig({
  componentId: 'promoBarButtonsContainer'
})`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 0 40px;
`;

const PromoLink = styled(Link).withConfig({
  componentId: 'promoBarLink'
})`
  text-align: start;
  text-transform: none;
`;

const ActionContainer = styled.div.withConfig({
  componentId: 'promoBarActionContainer'
})`
  display: flex;
`;

interface PromoContentProps {
  description: string;
  link: string;
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
}

interface ButtonContainerProps {
  handleClick: (event: React.MouseEvent<HTMLElement>) => void;
  link: string;
}

interface PromoBarProps {
  announcement: string;
  page?: {url: string};
  dismissed: boolean;
  setDismissed: (value: boolean) => void;
}

function PromoContent({
  description,
  handleClick,
  link,
}: PromoContentProps): JSX.Element {
  const {wide} = useContainerSize();
  return wide ? (
    <Paragraph>{description}</Paragraph>
  ) : (
    <PromoLink href={link} onClick={handleClick}>
      {description}
    </PromoLink>
  );
}

function ButtonContainer({
  handleClick,
  link,
}: ButtonContainerProps): JSX.Element {
  const {wide} = useContainerSize();
  return (
    <ActionContainer>
      {wide && <CTA href={link} onClick={handleClick}>{`continue`}</CTA>}
      <ButtonsContainer>
        <MenuButton onClick={handleClick} aria-label={`Close promotion bar`}>
          <X fill={Colors.Charcoal} />
        </MenuButton>
      </ButtonsContainer>
    </ActionContainer>
  );
}

export default function PromoBar({
  announcement,
  page,
  dismissed,
  setDismissed,
}: PromoBarProps): JSX.Element {
  const escapePressHandler = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setDismissed(true);
      }
    },
    [setDismissed],
  );

  const handleClick = useCallback(() => {
    return setDismissed(true);
  }, [setDismissed]);

  useEffect(() => {
    if (!dismissed) {
      document.addEventListener('keydown', escapePressHandler, false);
    }
    return () =>
      document.removeEventListener('keydown', escapePressHandler, false);
  }, [dismissed, escapePressHandler]);

  return (
    <Banner>
      <ThemeProvider
        theme={{
          fg: Colors.Charcoal,
          ctaFg: Colors.Blue,
          ctaBg: Colors.Transparent,
          ctaHoverFg: Colors.White,
          ctaHoverBg: Colors.Blue,
        }}
      >
        <ResponsiveContainer as={PromoBarContainer}>
          <PromoContent
            description={announcement}
            handleClick={handleClick}
            link={page && page.url}
          />
          <ButtonContainer handleClick={handleClick} link={page && page.url} />
        </ResponsiveContainer>
      </ThemeProvider>
    </Banner>
  );
}
