exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-boot-camps-tsx": () => import("./../../../src/pages/about/boot-camps.tsx" /* webpackChunkName: "component---src-pages-about-boot-camps-tsx" */),
  "component---src-pages-about-enterprise-tsx": () => import("./../../../src/pages/about/enterprise.tsx" /* webpackChunkName: "component---src-pages-about-enterprise-tsx" */),
  "component---src-pages-about-get-smarter-tsx": () => import("./../../../src/pages/about/get-smarter.tsx" /* webpackChunkName: "component---src-pages-about-get-smarter-tsx" */),
  "component---src-pages-about-impact-tsx": () => import("./../../../src/pages/about/impact.tsx" /* webpackChunkName: "component---src-pages-about-impact-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-leadership-tsx": () => import("./../../../src/pages/about/leadership.tsx" /* webpackChunkName: "component---src-pages-about-leadership-tsx" */),
  "component---src-pages-about-mission-tsx": () => import("./../../../src/pages/about/mission.tsx" /* webpackChunkName: "component---src-pages-about-mission-tsx" */),
  "component---src-pages-about-platform-tsx": () => import("./../../../src/pages/about/platform.tsx" /* webpackChunkName: "component---src-pages-about-platform-tsx" */),
  "component---src-pages-about-strategic-partnerships-tsx": () => import("./../../../src/pages/about/strategic-partnerships.tsx" /* webpackChunkName: "component---src-pages-about-strategic-partnerships-tsx" */),
  "component---src-pages-accessibility-tsx": () => import("./../../../src/pages/accessibility.tsx" /* webpackChunkName: "component---src-pages-accessibility-tsx" */),
  "component---src-pages-approach-2-uos-tsx": () => import("./../../../src/pages/approach/2uos.tsx" /* webpackChunkName: "component---src-pages-approach-2-uos-tsx" */),
  "component---src-pages-approach-ccc-tsx": () => import("./../../../src/pages/approach/ccc.tsx" /* webpackChunkName: "component---src-pages-approach-ccc-tsx" */),
  "component---src-pages-approach-index-tsx": () => import("./../../../src/pages/approach/index.tsx" /* webpackChunkName: "component---src-pages-approach-index-tsx" */),
  "component---src-pages-approach-learning-design-tsx": () => import("./../../../src/pages/approach/learning-design.tsx" /* webpackChunkName: "component---src-pages-approach-learning-design-tsx" */),
  "component---src-pages-approach-transparency-tsx": () => import("./../../../src/pages/approach/transparency.tsx" /* webpackChunkName: "component---src-pages-approach-transparency-tsx" */),
  "component---src-pages-careers-index-tsx": () => import("./../../../src/pages/careers/index.tsx" /* webpackChunkName: "component---src-pages-careers-index-tsx" */),
  "component---src-pages-careers-internships-tsx": () => import("./../../../src/pages/careers/internships.tsx" /* webpackChunkName: "component---src-pages-careers-internships-tsx" */),
  "component---src-pages-careers-jobs-tsx": () => import("./../../../src/pages/careers/jobs.tsx" /* webpackChunkName: "component---src-pages-careers-jobs-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-eudatacontrollers-tsx": () => import("./../../../src/pages/eudatacontrollers.tsx" /* webpackChunkName: "component---src-pages-eudatacontrollers-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-modern-slavery-statement-tsx": () => import("./../../../src/pages/modern-slavery-statement.tsx" /* webpackChunkName: "component---src-pages-modern-slavery-statement-tsx" */),
  "component---src-pages-newsroom-tsx": () => import("./../../../src/pages/newsroom.tsx" /* webpackChunkName: "component---src-pages-newsroom-tsx" */),
  "component---src-pages-partners-tsx": () => import("./../../../src/pages/partners.tsx" /* webpackChunkName: "component---src-pages-partners-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-purchase-order-terms-conditions-tsx": () => import("./../../../src/pages/purchase-order-terms-conditions.tsx" /* webpackChunkName: "component---src-pages-purchase-order-terms-conditions-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-pages-sub-processors-index-tsx": () => import("./../../../src/pages/sub-processors/index.tsx" /* webpackChunkName: "component---src-pages-sub-processors-index-tsx" */),
  "component---src-pages-terms-of-use-tsx": () => import("./../../../src/pages/terms-of-use.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-tsx" */),
  "component---src-templates-application-tsx": () => import("./../../../src/templates/application.tsx" /* webpackChunkName: "component---src-templates-application-tsx" */),
  "component---src-templates-job-detail-tsx": () => import("./../../../src/templates/job-detail.tsx" /* webpackChunkName: "component---src-templates-job-detail-tsx" */),
  "component---src-templates-leadership-detail-tsx": () => import("./../../../src/templates/leadership-detail.tsx" /* webpackChunkName: "component---src-templates-leadership-detail-tsx" */),
  "component---src-templates-location-detail-tsx": () => import("./../../../src/templates/location-detail.tsx" /* webpackChunkName: "component---src-templates-location-detail-tsx" */),
  "component---src-templates-newsroom-author-tsx": () => import("./../../../src/templates/newsroom-author.tsx" /* webpackChunkName: "component---src-templates-newsroom-author-tsx" */),
  "component---src-templates-newsroom-category-tsx": () => import("./../../../src/templates/newsroom-category.tsx" /* webpackChunkName: "component---src-templates-newsroom-category-tsx" */),
  "component---src-templates-newsroom-content-tsx": () => import("./../../../src/templates/newsroom-content.tsx" /* webpackChunkName: "component---src-templates-newsroom-content-tsx" */),
  "component---src-templates-partner-detail-tsx": () => import("./../../../src/templates/partner-detail.tsx" /* webpackChunkName: "component---src-templates-partner-detail-tsx" */),
  "component---src-templates-strategic-partner-detail-tsx": () => import("./../../../src/templates/strategic-partner-detail.tsx" /* webpackChunkName: "component---src-templates-strategic-partner-detail-tsx" */),
  "component---src-templates-team-detail-tsx": () => import("./../../../src/templates/team-detail.tsx" /* webpackChunkName: "component---src-templates-team-detail-tsx" */)
}

