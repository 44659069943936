import React from 'react';
import {useSpring} from 'react-spring';
import useHover from '@hzdg/use-hover';
import {Colors} from '@styles';
import useLocation from '@components/Subnavigation/useLocation';
import Link, {LinkProps} from './Link';

export default function TextLink<TState>({
  children,
  href,
  className,
  activeStyle,
  shouldInvertColors,
  ...rest
}: LinkProps<TState> & {shouldInvertColors?: boolean}): JSX.Element {
  const [isHovering, hoverProps] = useHover();
  const {location} = useLocation();
  const activeLink = location.pathname.includes(href);
  const animatedStyle = useSpring({
    to: {
      borderBottom: `1px solid ${
        isHovering && shouldInvertColors
          ? Colors.Charcoal
          : isHovering
          ? Colors.White
          : activeLink && shouldInvertColors
          ? Colors.Charcoal
          : activeLink
          ? Colors.White
          : Colors.Transparent
      }`,
    },
  });

  return (
    <Link
      className={className}
      href={href}
      activeStyle={activeStyle}
      animatedStyle={animatedStyle}
      {...hoverProps}
      {...rest}
    >
      {children}
    </Link>
  );
}
