import {useEffect} from 'react';

export default function useScrollBlocker(blocked = false): void {
  useEffect(() => {
    if (typeof document === 'undefined') return;
    if (blocked) {
      document.body.style.overflowY = 'hidden';
      document.body.style.pointerEvents = 'none';
    }
    return () => {
      document.body.style.overflowY = 'visible';
      document.body.style.pointerEvents = 'auto';
    };
  }, [blocked]);
}
