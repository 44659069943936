import {Colors} from '@styles';
import {Color, ThemeGetter} from '@styles/types';

/**
 * Use `logoFg` from the theme.
 * If `logoFg` is not provided by the theme,
 * will fall back to using the theme's Blue color.
 */
export const logoFg: ThemeGetter<Color> = ({theme}): Color =>
  theme.logoFg || Colors.Blue;

/**
 * Use `logoBg` from the theme.
 * If `logoBg` is not provided by the theme,
 * will fall back to using the theme's White color.
 */
export const logoBg: ThemeGetter<Color> = ({theme}): Color =>
  theme.logoBg || Colors.White;
