import React, {useContext} from 'react';
import * as styledComponents from 'styled-components';

import {Theme} from './types';
import * as baseTheme from './theme/base';

/**
 * A `styled` API that integrates with the 2U theme.
 *
 * Usage of `styled` will include type annotations and autocomplete
 * for the theme defined in `@styles/theme`.
 */
const {
  default: styled,
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider: StyledThemeProvider,
  ThemeContext,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<Theme>;

export type ThemeProviderProps = styledComponents.ThemeProviderProps<
  Theme,
  Theme
>;
const ThemeProvider = ({
  theme = baseTheme,
  ...props
}: ThemeProviderProps): JSX.Element => (
  <StyledThemeProvider {...props} theme={theme} />
);

const useTheme = (): Theme => {
  const theme = useContext(ThemeContext);
  return theme;
};

const GlobalStyles = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
    height: 100%;
    width: 100%;
    position: relative;
    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
  };
`;

export {
  css,
  createGlobalStyle,
  keyframes,
  ThemeProvider,
  useTheme,
  GlobalStyles,
};
export default styled;
