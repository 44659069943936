/* eslint-disable react/prop-types, @typescript-eslint/explicit-function-return-type */
import React from 'react';
import {ThemeProvider} from '@styles';

export const wrapRootElement = ({element}) => (
  <ThemeProvider>{element}</ThemeProvider>
);

/* needed for gatbsy-image lazy loading to work in IE/Safari */
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    // TODO: Move this into a polyfill chunk or something?
    await import('intersection-observer');
  }
};

export const onRouteUpdate = ({ prevLocation }) => {
  function trackSegmentPage() {

    window.analytics && window.analytics.page();
    
  }

  // This `if/then` logic relates to the `delayLoad` functionality to help prevent
  // calling `trackPage` twice. If you don't use that feature, you can ignore. Here
  // call `trackPage` only _after_ we change routes (on the client).
  if (prevLocation && window.segmentSnippetLoaded === false) {
    window.segmentSnippetLoader(() => {
      trackSegmentPage();
    });
  } else {
    trackSegmentPage();
  }
};