import React from 'react';

import {theme, styled} from '@styles';
import {SvgIconProps} from '.';

const StyledTwitterIcon = styled.svg.withConfig({
  componentId: 'twitterIcon'
})<SvgIconProps>`
  margin: ${({margin}) => (margin ? margin : null)};
  & path {
    fill: ${({fill}) => (fill ? fill : theme.ctaFg)};
  }
`;

export default function TwitterIcon({
  fill,
  ariaHidden = true,
  margin,
}: SvgIconProps): JSX.Element {
  return (
    <StyledTwitterIcon //changed to X
      width="30"
      height="30"
      viewBox="0 0 30 30"
      aria-hidden={ariaHidden}
      margin={margin}
      fill={fill}
    >
      <g fill="none" fillRule="evenodd">
        <path d="M15.175,13.665l-4.226-6.045h-2.254l5.236,7.49.658.941h0s4.482,6.412,4.482,6.412h2.254l-5.493-7.857-.658-.941ZM15,.36C6.716.36,0,6.983,0,15.154s6.716,14.795,15,14.795,15-6.623,15-14.795S23.284.36,15,.36ZM18.381,23.518l-4.536-6.602-5.679,6.602h-1.468l6.495-7.55L6.698,6.516h4.954l4.295,6.251,5.378-6.251h1.468l-6.194,7.199h0l6.736,9.803h-4.954Z" />
      </g>
    </StyledTwitterIcon>
  );
}
