import React from 'react';
import Helmet from 'react-helmet';
import {useStaticQuery, graphql} from 'gatsby';

interface Meta {
  name: string;
  content: string;
}

interface MetaProps {
  title: string;
  seoTitle?: string;
  description: string;
  lang?: string;
  meta?: Meta[];
  keywords?: string[];
  image?: string;
}

const DEFAULT_META_QUERY = graphql`
  query DEFAULT_META_QUERY($width: Int = 417, $height: Int, $quality: Int) {
    site {
      siteMetadata {
        title
        description
        author
      }
    }
    logoFile: file(relativePath: {eq: "2u-logo.png"}) {
      ...fixedImageFragment
    }
  }
`;

export default function Meta({
  title,
  seoTitle,
  description,
  lang = `en`,
  meta = [],
  keywords = [],
  image,
}: MetaProps): JSX.Element {
  const data = useStaticQuery(DEFAULT_META_QUERY);
  const metaDescription = description || data.site.siteMetadata.description;
  const imageUrl = image ? `https://2u.com${image}` : `https://2u.com${data.logoFile.src}`;
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={seoTitle ? seoTitle : title}
      titleTemplate={`%s | ${data.site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: data.site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: `og:image`,
          content: imageUrl,
        },
        {
          property: `twitter:image`,
          content: imageUrl,
        },
      ]
        .concat(
          keywords.length > 0
            ? {
                name: `keywords`,
                content: keywords.join(`, `),
              }
            : [],
        )
        .concat(meta)}
    />
  );
}
