import React, {Fragment} from 'react';
import loadable from '@loadable/component';
import {
  useContainerSize,
  ResponsiveContainer,
  FontScale,
} from '@components/layout';
import {Link, TextLink, SocialLink, OneTrustToggleLink} from '@components/Link/';
import {Paragraph, Headline} from '@components/typography';
import {FooterLogo} from '@components/icons';
import {styled, ThemeProvider, Colors, Fonts} from '@styles';
import {Section} from '@components/sectioning';
import {Category} from '@util/useLatestPosts';
import LatestCategoriesLoader from '@util/LatestCategoriesLoader';
import {footerUtilityLinks} from '../Menu/navigationData';
import {SpeedbumpDoNotSellLink} from '@components/Link';
import {LxfSpeedbump} from '@components/Link/SpeedbumpsDoNotSell';

interface RowContainerProps {
  margin?: string;
}

type LinkProps = React.PropsWithChildren<{
  href: string;
}>;

const FooterContainer = styled(
  // eslint-disable-next-line react/display-name
  React.forwardRef<HTMLElement, React.HTMLAttributes<HTMLElement>>(
    (props, forwardedRef) => (
      <FontScale {...props} as="footer" role="contentinfo" ref={forwardedRef} />
    ),
  ),
).withConfig({
  componentId: 'footerContainer'
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  width: calc(100% - 4em);
  background: ${Colors.Blue};
  color: ${Colors.White};
  padding: 2em;
  &.wide {
    height: 400px;
    padding: 2.6em 2em;
  }
`;

const ColumnContainer = styled.ul.withConfig({
  componentId: 'footerColumnContainer'
}).attrs(() => ({role: 'menu'}))`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  margin: 10px 0;
  padding: 0;
  .wide & {
    width: fit-content;
    margin: 0;
  }
`;

const RowContainer = styled.ul.withConfig({
  componentId: 'footerRowContainer'
}).attrs(() => ({role: 'menu'}))<{
  margin?: number | string;
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: ${({margin}) => (margin ? margin : '0')};
  padding: 0;
`;

const FooterInnerContentContainer = styled(Section).withConfig({
  componentId: 'footerInnerContentContainer'
})`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .wide & {
    flex-direction: row;
  }
`;

const LogoLink = styled(Link).withConfig({
  componentId: 'footerLogoLink'
})`
  display: flex;
  align-items: center;
  outline-color: ${Colors.White};
`;

const LinkContainer = styled.li.withConfig({
  componentId: 'footerLinkContainer'
}).attrs(() => ({role: 'menuitem'}))`
  list-style: none;
`;

const StyledMainLinkContainer = styled(LinkContainer).withConfig({
  componentId: 'footerMainLinkContainer'
})`
  margin: 2.5px 0;
`;

const StyledUtilityLinkContainer = styled(LinkContainer).withConfig({
  componentId: 'footerUtilityLinkContainer'
})`
  margin: 0 10px;
`;

const StyledFooterUtilityLink = styled(TextLink).withConfig({
  componentId: 'footerUtilityLink'
})`
  outline-color: ${Colors.White};
`;

const StyledFooterMainLink = styled(TextLink).withConfig({
  componentId: 'footerMainLink'
})`
  outline-color: ${Colors.White};
  text-transform: none;
  text-align: left;
`;

const StyledFooterLegalLink = styled(TextLink).withConfig({
  componentId: 'footerLegalLink'
})`
  ${Fonts.ProximaNova.variants.Bold};
  outline-color: ${Colors.White};
`;

const StyledFooterLegalModalLink = styled.span.withConfig({
  componentId: 'footerLegalModalLink'
})`
  ${Fonts.ProximaNova.variants.Bold};
  outline-color: ${Colors.White};
  text-align: left;
`;

const StyledFooterLegalLinkContainer = styled(LinkContainer).withConfig({
  componentId: 'footerLegalLinkContainer'
})`
  margin: ${12 / 25}em 0;
`;

const FooterColumnTitle = styled(Headline).withConfig({
  componentId: 'footerColumnTitle'
})`
  font-size: ${19 / 16}em;
  margin: 0;
  white-space: nowrap;

  .wide & {
    font-size: ${19 / 16}em;
  }
`;

const FooterCopyright = styled(Paragraph).withConfig({
  componentId: 'footerCopyright'
})`
  font-size: 0.9em;
  margin: 0;
`;

const FooterSitemapContainer = styled(Section).withConfig({
  componentId: 'footerSitemapContainer'
})`
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
  grid-auto-flow: row;
  grid-template-rows: auto;
  grid-column-gap: 15px;
  grid-row-gap: 20px;
  width: 100%;
  margin: 20px 0;
  .wide & {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(20%, 1fr));
    grid-auto-flow: column;
    grid-row-gap: 0;
    width: 75%;
    margin: 0;
  }
`;

const RowColumnContainer = styled(RowContainer).withConfig({
  componentId: 'footerRowColumnsContainer'
})`
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  .wide & {
    flex-direction: row;
    justify-content: flex-start;
  }
`;

function FooterMainLink({href, children}: LinkProps): JSX.Element {
  return (
    <StyledMainLinkContainer>
      <StyledFooterMainLink href={href}>{children}</StyledFooterMainLink>
    </StyledMainLinkContainer>
  );
}

function FooterUtilityLink({href, children}: LinkProps): JSX.Element {
  return (
    <StyledUtilityLinkContainer>
      <StyledFooterUtilityLink href={href}>{children}</StyledFooterUtilityLink>
    </StyledUtilityLinkContainer>
  );
}

function FooterLegalLink({href, children}: LinkProps): JSX.Element {
  return (
    <StyledFooterLegalLinkContainer>
      <StyledFooterLegalLink href={href}>{children}</StyledFooterLegalLink>
    </StyledFooterLegalLinkContainer>
  );
}

function ContactLinks(): JSX.Element | null {
  const {wide} = useContainerSize();
  if (!wide) {
    return (
      <ColumnContainer aria-label="Contact Us and External links">
        {footerUtilityLinks.map(({href, text}, index) => (
          <FooterMainLink key={index} href={href}>
            {text}
          </FooterMainLink>
        ))}
      </ColumnContainer>
    );
  } else {
    return null;
  }
}

function ContactUtilityLinks(): JSX.Element | null {
  const {wide} = useContainerSize();
  if (wide) {
    return (
      <RowContainer margin="0 20px" aria-label="Contact Us and External links">
        {footerUtilityLinks.map(({href, text}, idx) => (
          <FooterUtilityLink key={`${idx}-text`} href={href}>
            {text}
          </FooterUtilityLink>
        ))}
      </RowContainer>
    );
  } else {
    return null;
  }
}

export default function Footer(): JSX.Element {
  return (
    <ThemeProvider
      theme={{fg: Colors.White, bg: Colors.Blue, maxPadding: '2em'}}
    >
      <ResponsiveContainer as={FooterContainer}>
        <FooterInnerContentContainer>
          <ColumnContainer>
            <LinkContainer>
              <LogoLink href="/" aria-label="2U homepage">
                <FooterLogo />
              </LogoLink>
            </LinkContainer>
            <LinkContainer>
              <RowContainer
                margin="20px 0"
                aria-label="Links to 2U's social media"
              >
                <LinkContainer>
                  <SocialLink
                    href={`https://www.facebook.com/2Ueducation`}
                    type="facebook"
                    margin="0 5px 0 0"
                  />
                </LinkContainer>
                <LinkContainer>
                  <SocialLink
                    href={`https://www.instagram.com/2uinc/`}
                    type="instagram"
                  />
                </LinkContainer>
                <LinkContainer>
                  <SocialLink
                    href={`https://x.com/2U_education`}
                    type="twitter"
                  />
                </LinkContainer>
                <LinkContainer>
                  <SocialLink
                    href={`https://www.youtube.com/channel/UCmbq-RiyFt5NynDsAM7Z7_g/`}
                    type="youtube"
                  />
                </LinkContainer>
                <LinkContainer>
                  <SocialLink
                    href={`https://www.linkedin.com/company/2u/`}
                    type="linkedin"
                    margin="0 0 0 5px"
                  />
                </LinkContainer>
              </RowContainer>
            </LinkContainer>
            <FooterLegalLink href="/contact/">Contact Us</FooterLegalLink>
            <FooterLegalLink href="/terms-of-use/">
              Terms & Conditions
            </FooterLegalLink>
            <FooterLegalLink href="/privacy-policy/">
              Privacy Policy
            </FooterLegalLink>
            <FooterLegalLink href="/cookie-policy/">
              Cookie Policy
            </FooterLegalLink>
            <StyledFooterLegalLinkContainer>
              <OneTrustToggleLink
                href="javascript:OneTrust.ToggleInfoDisplay();"
              >
                <StyledFooterLegalModalLink>
                  Your Privacy Choices
                </StyledFooterLegalModalLink>
              </OneTrustToggleLink>
            </StyledFooterLegalLinkContainer>
            <FooterLegalLink href="/modern-slavery-statement/">
              Modern Slavery Statement
            </FooterLegalLink>
             <FooterLegalLink href="/static/Form 8937_Organizational Action_2U LLC.pdf" target="_blank">
              Tax Forms
            </FooterLegalLink>
          </ColumnContainer>
          <FooterSitemapContainer>
            <ColumnContainer aria-label="Links to About us landing and detail pages">
              <FooterColumnTitle>About Us</FooterColumnTitle>
              <FooterMainLink href="/about/">Overview</FooterMainLink>
              <FooterMainLink href="https://www.edx.org/">edX</FooterMainLink>
              {/* <FooterMainLink href="https://investor.2u.com/">
                Investors
              </FooterMainLink> */}
              {/* <FooterMainLink href="/about/get-smarter/">
                GetSmarter
              </FooterMainLink> */}
              {/* <FooterMainLink href="/about/boot-camps/">
                Boot Camps
              </FooterMainLink> */}
              {/* <FooterMainLink href="/about/enterprise/">
                For Enterprise
              </FooterMainLink>
              <FooterMainLink href="/about/strategic-partnerships/">
                Collaborators
              </FooterMainLink> */}
              <FooterMainLink href="/about/leadership/">Leaders</FooterMainLink>
              <FooterMainLink href="/about/impact/">
                Impact & Outcomes
              </FooterMainLink>
            </ColumnContainer>
            {/* <ColumnContainer aria-label="Links to Our Approach landing and detail pages">
              <FooterColumnTitle>Our Approach</FooterColumnTitle> */}
              {/* <FooterMainLink href="/approach/">Overview</FooterMainLink> */}
              {/* <FooterMainLink href="/approach/ccc/">Offerings</FooterMainLink> */}
              {/* <FooterMainLink href="/approach/2uos/">2UOS</FooterMainLink> */}
              {/* <FooterMainLink href="/approach/learning-design/">
                Learning Design
              </FooterMainLink> */}
              {/* <FooterMainLink href="/approach/transparency/">
                Transparency
              </FooterMainLink> */}
              {/* <FooterMainLink href="/about/impact/">
                Impact & Outcomes
              </FooterMainLink> */}
            {/* </ColumnContainer> */}
            <ColumnContainer aria-label="Links to Our Partners landing page">
              <FooterColumnTitle>Our Partners</FooterColumnTitle>
              <FooterMainLink href="/partners/">
                Meet Our Partners
              </FooterMainLink>
            </ColumnContainer>
            <ColumnContainer aria-label="Links to Careers landing and detail pages">
              <FooterColumnTitle>Careers</FooterColumnTitle>
              <FooterMainLink href="/careers/">Overview</FooterMainLink>
              <FooterMainLink href="/careers/jobs/">
                All Openings
              </FooterMainLink>
              {/* <FooterMainLink href="/careers/internships/">
                Internships
              </FooterMainLink> */}
              {/* <FooterMainLink href="/">Culture/D+I</FooterMainLink> */}
            </ColumnContainer>
            <LatestCategoriesLoader>
              {latestCategories => (
                <ColumnContainer aria-label="Links to the Latest landing and Latest Category pages">
                  <FooterColumnTitle>Media</FooterColumnTitle>
                  <FooterMainLink href="/newsroom/">Newsroom</FooterMainLink>
                  {/* <FooterMainLink href="/press/">Press</FooterMainLink> */}
                  {/* {latestCategories &&
                    latestCategories.length > 0 &&
                    latestCategories.map(
                      (latestCategory: {name: string; slug: string}) => {
                        return (
                          <FooterMainLink
                            key={latestCategory.name}
                            href={`/newsroom/${latestCategory.slug}/`}
                          >
                            {latestCategory.name}
                          </FooterMainLink>
                        );
                      },
                    )} */}
                </ColumnContainer>
              )}
            </LatestCategoriesLoader>
            <ContactLinks />
          </FooterSitemapContainer>
        </FooterInnerContentContainer>
        <RowColumnContainer>
          <FooterCopyright>
            {`Copyright ${new Date().getFullYear()} 2U. All Rights Reserved.`}
          </FooterCopyright>
          {/* <ContactUtilityLinks /> */}
        </RowColumnContainer>
      </ResponsiveContainer>
    </ThemeProvider>
  );
}
