import {useState, useEffect} from 'react';

const isClient = typeof window === 'object';

export function useSessionStorage<T>(
  key: string,
  initialValue?: T,
  raw?: boolean,
): [T, (value: T) => void] {
  const [state, setState] = useState<T>(() => {
    if (!isClient || !window.sessionStorage) {
      return initialValue as T;
    }
    try {
      const sessionStorageValue = sessionStorage.getItem(key);
      if (typeof sessionStorageValue !== 'string') {
        sessionStorage.setItem(
          key,
          /**
           * if raw argument is provided, the value won't be serialized.
           */
          raw ? String(initialValue) : JSON.stringify(initialValue),
        );
        return initialValue;
      } else {
        return raw
          ? sessionStorageValue
          : JSON.parse(sessionStorageValue || 'null');
      }
    } catch {
      return initialValue;
    }
  });

  useEffect(() => {
    try {
      const serializedState = raw ? String(state) : JSON.stringify(state);
      sessionStorage.setItem(key, serializedState);
    } catch {
      // noop if it throws while serializing the value.
    }
  });

  return [state, setState];
}
