import {Color, ThemeGetter} from '@styles/types';

/**
 * Use `ctaFg` from the theme.
 * If `ctaFg` is not provided by the theme,
 * will fall back to using the theme's `fg` color.
 */
export const ctaFg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaFg || theme.fg;

/**
 * Use `ctaBg` from the theme.
 * If `ctaBg` is not provided by the theme,
 * will fall back to using the theme's `bg` color.
 */
export const ctaBg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaBg || theme.bg;

/**
 * Use `ctaIconFg` from the theme.
 * If `ctaIconFg` is norot pvided by the theme,
 * will fall back to using the theme's `fg` color.
 */
export const ctaIconFg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaIconFg || theme.ctaFg || theme.fg;

/**
 * Use `ctaIconBg` from the theme.
 * If `ctaIconBg` is norot pvided by the theme,
 * will fall back to using the theme's `fg` color.
 */
export const ctaIconBg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaIconBg || theme.ctaBg || theme.bg;

/**
 * Use `ctaHoverBg` from the theme.
 * If `ctaHoverBg` is not provided by the theme,
 * will fall back to using the theme's `fg` color.
 */
export const ctaHoverBg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaHoverBg || theme.fg;

/**
 * Use `ctaHoverFg` from the theme.
 * If `ctaHoverFg` is not provided by the theme,
 * will fall back to using the theme's `bg` color.
 */
export const ctaHoverFg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaHoverFg || theme.bg;

/**
 * Use `ctaBorder` from the theme.
 * If `ctaBorder` is not provided by the theme,
 * will fall back to using the theme's `ctaFg` color.
 */
export const ctaBorder: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaBorder || theme.ctaFg;

/**
 * Use `ctaBorderHover` from the theme.
 * If `ctaBorderHover` is not provided by the theme,
 * will fall back to using the theme's `ctaBg` color.
 */
export const ctaBorderHover: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaBorderHover || theme.ctaBg;

/**
 * Use `ctaActiveBg` from the theme.
 * If `ctaActiveBg` is not provided by the theme,
 * will fall back to using the theme's `ctaBg` color.
 */
export const ctaActiveBg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaActiveBg || theme.ctaBg;

/**
 * Use `ctaActiveFg` from the theme.
 * If `ctaActiveFg` is not provided by the theme,
 * will fall back to using the theme's `ctaFg` color.
 */
export const ctaActiveFg: ThemeGetter<Color> = ({theme}): Color =>
  theme.ctaActiveFg || theme.ctaFg;
