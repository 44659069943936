import React from 'react';
import {styled, Colors} from '@styles';

import {animated} from 'react-spring';
import {DefaultLinkTextStyles} from './Link';

interface OneTrustToggleProps {
  children: JSX.Element;
  href: string;
  margin?: string;
}

const StyledOneTrustToggleLink = styled(animated.a).withConfig({
    componentId: 'StyledOneTrustToggleLink'
  })`
    ${DefaultLinkTextStyles}
  `;

export default function OneTrustToggleLink({
  children,
  href,
  ...props
}: OneTrustToggleProps): JSX.Element | null {
  return (
    <StyledOneTrustToggleLink
      {...props}
      href={href}
      aria-label={``}
    >
      { children }
    </StyledOneTrustToggleLink>
  );
}
